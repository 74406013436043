import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientForm } from '../../../models/ClientForm';
import LanguageUtils from '../../../utils/LanguageUtils';
import DateUtils from '../../../utils/DateUtils';
import { ClientFormUserRole, ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';
import { useRecoilValue } from 'recoil';
import { currentFormUsersAtom, formSectionUsersAtom } from '../../../recoil/atoms/Forms';
import { FormType } from '../../../models/FormTypes';
import { DocumentResponse } from '../../../models/Document';
import { FileUtils } from '../../../utils/FileUtils';
import { ClientModule } from '../../../models/ClientModule';
import ModuleService from '../../../services/ModuleService';
import { AccessTypeKeys } from '../../../models/Access';
import StringUtils from '../../../utils/StringUtils';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';
import PeriodicReviewRecurrence from '../../shared/periodic-review/PeriodicReviewRecurrence';
import InfoIcon from '../../shared/icon/InfoIcon';
import Tooltip from '../../shared/Tooltip';
import { deDuplicate } from '../../../utils/ListUtils';
import useStatusText from '../../../hooks/useStatusText';

const Heading: FC<{ title: string }> = ({ title }) => <div className="border-b px-4 pb-2 font-medium">{title}</div>;
const Property: FC<{ title: string | ReactElement; value: number | string | ReactElement }> = ({ title, value }) => (
  <div className="text-dpm-14 my-4 flex flex-col items-start justify-between gap-2 px-4">
    <div className="font-medium">{title}</div>
    <div
      className="text-left"
      style={{
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      }}
    >
      {value}
    </div>
  </div>
);
const WrappedInfo: FC<{ text: string }> = ({ text }) => (
  <Tooltip text={text}>
    {(tooltip) => (
      <span {...tooltip}>
        <InfoIcon className="h-4 w-4" />
      </span>
    )}
  </Tooltip>
);

const Info = (text: string) => <WrappedInfo text={text} />;
const ModuleName: FC<{ clientModuleId: string }> = ({ clientModuleId }) => {
  const [module, setModule] = useState<ClientModule | null>(null);
  useEffect(() => {
    ModuleService.getModule(clientModuleId).then((res) => {
      setModule(res.data);
    });
  }, [clientModuleId]);

  const moduleName = useMemo(() => {
    if (!module) {
      return '-';
    }
    return LanguageUtils.getTranslation('name', module.templateModule.translations);
  }, [module]);

  return moduleName;
};

type Props = {
  clientForm: DocumentResponse | ClientForm;
};

const CondensedActivityDetails: FC<Props> = (props) => {
  const { clientForm } = props;
  const { t } = useTranslation(['form', 'common', 'documents']);
  const formUsers = useRecoilValue(currentFormUsersAtom);
  const formSectionUsers = useRecoilValue(formSectionUsersAtom);
  const allUsers = useMemo(() => formUsers.concat(formSectionUsers), [formSectionUsers, formUsers]);
  const statusText = useStatusText(clientForm);

  const applicableUserRoles = useMemo(() => {
    const result: ClientFormUserRoleValues[] = [ClientFormUserRole.Owner];

    if (clientForm.form.requiresApproval) result.push(ClientFormUserRole.Approver);
    if (clientForm.form.requiresValidation) result.push(ClientFormUserRole.Validator);

    return result.concat([ClientFormUserRole.Contributor, ClientFormUserRole.Viewer]);
  }, [clientForm.form.requiresApproval, clientForm.form.requiresValidation]);

  const usersForRole = useCallback(
    (role: ClientFormUserRoleValues) => {
      return (
        deDuplicate(
          allUsers.filter((x) => x.role === role),
          'id',
        )
          .reduce((prev, curr) => `${curr.firstName} ${curr.lastName}, ${prev}`, '')
          .slice(0, -2) || '-'
      );
    },
    [allUsers],
  );

  return (
    <div className="mt-4">
      <Heading title={t('left-tabs.details.headings.details')} />

      <Property
        title={t('left-tabs.details.properties.title')}
        value={clientForm.subtitle || LanguageUtils.getTranslation('title', clientForm.form.translations)}
      />
      <Property title={t('form:left-tabs.details.properties.version')} value={`v${clientForm.majorVersion}`} />
      {clientForm.type === FormType.Document && (
        <Property title={t('form:left-tabs.details.properties.class')} value={<ModuleName clientModuleId={clientForm.clientModuleId} />} />
      )}
      {!clientForm.form.isSystem && (
        <Property title={t('form:left-tabs.details.properties.type')} value={LanguageUtils.getTranslation('title', clientForm.form.translations)} />
      )}
      {clientForm.type === FormType.Document && (
        <Property
          title={t('form:left-tabs.details.properties.number')}
          value={StringUtils.addLeadingZeros((clientForm as DocumentResponse).documentNumber)}
        />
      )}
      <Property title={t('form:left-tabs.details.properties.status')} value={statusText} />
      <Property title={t('form:left-tabs.details.properties.last-modified')} value={DateUtils.formatDate(new Date(clientForm.modifiedUtc))} />
      {clientForm.type === FormType.Document && clientForm.form.isSystem && (
        <Property title={t('form:left-tabs.details.properties.size')} value={FileUtils.formatSize((clientForm as DocumentResponse).fileSize)} />
      )}
      {clientForm.type === FormType.Document && clientForm.form.isSystem && (
        <Property
          title={t('form:left-tabs.details.properties.format')}
          value={FileUtils.getFileExtensionFromMimeType((clientForm as DocumentResponse).fileFormat)}
        />
      )}
      {clientForm.type === FormType.Document && (
        <>
          <Heading title={t('left-tabs.details.properties.access')} />
          <div className="text-dpm-14 my-4 px-4">
            {t(AccessTypeKeys[(clientForm as DocumentResponse).accessType], { client: clientForm?.clientName })}
          </div>
        </>
      )}
      <Heading title={t('left-tabs.details.headings.users')} />
      {applicableUserRoles.map((x) => (
        <Property key={x} title={t(ClientFormUserRoleKeys[x])} value={usersForRole(x)} />
      ))}
      {clientForm.periodicReviewConfig && (
        <>
          <Heading title={t('form:info-modal.details.sections.periodic-review.title')} />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.due-date"
                components={{
                  InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.due-date-help')),
                }}
              />
            }
            value={
              clientForm.periodicReviewDueDateUtc
                ? DateUtils.formatDate(new Date(clientForm.periodicReviewDueDateUtc))
                : t('form:info-modal.details.sections.periodic-review.fields.due-date-placeholder')
            }
          />
          <Property
            title={t('form:info-modal.details.sections.periodic-review.fields.recurrence')}
            value={<PeriodicReviewRecurrence source={clientForm} />}
          />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.advanced-notice"
                components={{
                  InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.advanced-notice-help')),
                }}
              />
            }
            value={PeriodicReviewUtils.getAdvancedNoticeText(clientForm.periodicReviewConfig, t)}
          />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.completed-count"
                components={{
                  InfoIcon: Info(
                    t('form:info-modal.details.sections.periodic-review.fields.completed-count-help', {
                      Count: clientForm.periodicReviewCount,
                    }),
                  ),
                }}
              />
            }
            value={clientForm.periodicReviewCount}
          />
          <Property title={t('form:info-modal.details.sections.periodic-review.fields.goal')} value={clientForm.periodicReviewConfig.goal || '-'} />
          <Property
            title={t('form:info-modal.details.sections.periodic-review.fields.repercussions')}
            value={clientForm.periodicReviewConfig.repercussions || '-'}
          />
        </>
      )}
    </div>
  );
};

export default CondensedActivityDetails;
